import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import Text from 'components/atoms/Text/Text'
import { CustomModal } from 'components/organisms/MaterialModal/MaterialModal'
import { useTranslation } from 'react-i18next'
import CancelButton from 'components/atoms/ColorButton/CancelButton'
import HtmlEditor from '../HtmlEditor/HtmlEditor'

const TWO_WEEKS_IN_MINUTES = 20160
const ONE_MINUTE_IN_MINUTES = 1

export interface RequestTrainingModalProps {
  modalStatus: boolean
  setModalStatus: Function
  onClose?: Function
  trainee: any
  product: any
  learningType: any
  requestTrainingNotification: Function
  mentorName: string
  channelName: string
}

const useStyles = makeStyles(() => ({
  modalContainer: {
    padding: '20px 70px 20px 20px',
  },
  title: {
    marginBottom: 0,
  },
  editor: {
    marginBottom: 20,
  },
  saveBtn: {
    marginRight: '1.25rem',
  },
  cancelBtn: {
    backgroundColor: '#999999',
    marginTop: '1rem',
  },
  cancelBtnDesktop: {
    marginTop: 0,
  },
  btns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: '1rem',
  },
  container: {
    padding: '20px 0px 0px 0px',
  },
}))

const RequestTrainingModal: React.SFC<RequestTrainingModalProps> = ({
  modalStatus,
  setModalStatus,
  onClose,
  trainee,
  product,
  learningType,
  requestTrainingNotification,
  mentorName,
  channelName
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [editorValue, setEditorValue] = useState()
  useEffect(() => {
    setEditorValue(
      t('request_training_email', {
        name: `${trainee?.firstName} ${trainee?.lastName}`,
        productName: product?.name,
        mentorName,
        phaseName: learningType?.name,
        channelName
      }),
    )
  }, [learningType, product, mentorName, trainee])

  function toggleModal(value) {
    if (!value && onClose) {
      onClose()
    }
    setModalStatus(value)
  }

  return (
    <CustomModal
      open={modalStatus}
      maxWidth="md"
      toggleModal={toggleModal}
      header={
        <Grid container spacing={4} className={classes.container}>
          <Grid item xs={12}>
            <Text variant="h3" className={classes.title}>
              {t('request_training')}
            </Text>
          </Grid>
        </Grid>
      }
      body={
        <div className={classes.modalContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <HtmlEditor
                className={classes.editor}
                value={editorValue}
                onChange={setEditorValue}
              />
              <div className={classes.btns}>
                <div>
                  <CustomButton
                    className={classes.saveBtn}
                    color="primary"
                    onClick={() => {
                      requestTrainingNotification({
                        email: trainee.email,
                        message: editorValue,
                        repeatCount: 4,
                        sentCount: 1,
                        repeatInterval: ONE_MINUTE_IN_MINUTES,
                        productId: product.id,
                        learningTypeId: learningType.id,
                        customData: { subject: { channelName } }
                      })
                      setModalStatus(false)
                    }}
                  // onClick={() => saveArgumentationFeedback(argumentation?.id)}
                  >
                    {t('send_email')}
                  </CustomButton>
                  <CancelButton
                    variant="outlined"
                    onClick={() => {
                      setModalStatus(false)
                    }}
                  >
                    {t('cancel')}
                  </CancelButton>

                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      }
    />
  )
}

export default RequestTrainingModal
